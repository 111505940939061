import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {Paginated} from '../api.service';
import {User} from '../../models/user';
import {map, takeUntil} from 'rxjs/operators';
import {BaseCacheArgs, SortingFn, subjectIsObserved} from './cache-base';
import {CacheRepo} from './cache-repo';

export interface PagedCacheRepoArgs<T> extends BaseCacheArgs<T> {
    apiFunction: () => Observable<Paginated<T[]>>;
    filters?: {[key: string]: any};
    pageSize?: number;
}

export class PagedCacheRepo<T, C> {
    get filters(): {[p: string]: any} {
        return this._filters;
    }

    set filters(value: {[p: string]: any}) {
        this._cancelRequest$.next();
        this._filters = value;
        this.reset();
    }

    ttl: number;
    apiFunction: (filters?) => Observable<Paginated<T[]>>;
    constructorClass;
    compareProp = 'id';
    private _filters: {[key: string]: any};
    private _count: number;
    private _count$: ReplaySubject<number> = new ReplaySubject(1);
    private _items: C[] = null;
    private readonly _ttlSecs: number;
    private readonly _sortingFn: SortingFn;
    private _subjects: Subject<C[]>[] = [];
    private _permissions: string[];
    private _user: User;

    private readonly _pageSize: number;
    private _lastRequestedPage = 0;

    private _cancelRequest$ = new Subject<void>();

    constructor(args: PagedCacheRepoArgs<T>) {
        if (args) {
            this.apiFunction = args.apiFunction;
            this._filters = args.filters || {};
            this._ttlSecs = args.ttlSecs || 600;
            this._permissions = args.permissions;
            args.user?.subscribe(u => this._user = u);
            if (args.sortingFn) this._sortingFn = args.sortingFn;
            if (args.compareProp) this.compareProp = args.compareProp;
            if (args.constructorClass) this.constructorClass = args.constructorClass;
            this._pageSize = args.pageSize || 200;
        }
    }

    get count$() {
        if (!this._count && this._count !== 0 && !this._count$.observers.length) {
            setTimeout(() => {
                if (!this._subjects.some(x => x.observers.length)) {
                    this.getCount();
                }
            });
        }

        return this._count$;
    }

    getCount() {
        this.apiFunction({...this.filters, page_size: 1, page: 1}).subscribe(x => {
            this._count = x.count;
            this._count$.next(x.count);
        });
        return this._count$;
    }

    getData(page: number, forceFetch = false): Observable<C[]> {
        return new Observable(observer => {
            if (this._permissions?.length && this._permissions.every(p => !this._user.permissions.includes(p))) {
                observer.next(null);
                observer.complete();
                return;
            }

            const sub = this.getSubjectForPage(page).subscribe(observer);

            if (page - this._lastRequestedPage === 1 && (!this._items || this._items.length !== this._count)) {
                this.resetTTL();
                this._lastRequestedPage = page;

                this.apiFunction({...this._filters, page_size: this._pageSize, page}).pipe(takeUntil(this._cancelRequest$)).subscribe(res => {
                    this._count = res.count;
                    // this._count$.next(res.count);

                    // We got the first state of the repo, so the 'loading'
                    if (!this._items) this._items = [];

                    res.results.forEach(item => this.cacheItem(item));
                    this.cleanupSubjects();
                    this.updateSubjects(this._items);
                });
            } else if (forceFetch || !this.ttl || this.ttl < Date.now()) {
                this.refreshItems();
            } else if (this._items) {
                observer.next(this._items.slice(0, this._pageSize * page));
            }

            return () => sub.unsubscribe();
        });
    }

    processItem(x) {
        return CacheRepo.processItem(this, x);
    }

    refreshItems(): Observable<C[]> {
        this.resetTTL();

        const refreshObs$ = this.apiFunction({...this._filters, page_size: this._lastRequestedPage * this._pageSize, page: 1})
            .pipe(map(res => {
                this._items = [];
                res.results.forEach(item => this.cacheItem(item, this._sortingFn, this.compareProp));
                this._count = res.count;
                this._count$.next(res.count);
                return this._items;
            }));

        refreshObs$.subscribe(items => {
            this.cleanupSubjects();
            this.updateSubjects(items);
        });
        return refreshObs$;
    }

    add(item: any, sortingFn: SortingFn = this._sortingFn, compareProp = 'id') {
        const items = this._items;
        const isCached = items?.some(x => x[compareProp] === item[compareProp]);

        if (isCached) return;

        this.addToCount(1);

        if (!items) return;

        const x = this.processItem(item);

        let i = sortingFn ? items.findIndex(b => sortingFn(x, b) < 0) : items.length;
        if (i < 0) i = items.length;
        if (this._count < this._pageSize || this._count % this._pageSize !== 0) {
            items.splice(i, 0, x);
        } else {
            items.splice(i, 0, x);
            items.pop();
        }
        this.cleanupSubjects();
        this.updateSubjects(items);
    }

    remove(item: C, compareProp = 'id') {
        if (this._items) {
            const i = this._items.findIndex(x => x[compareProp] === item[compareProp]);
            if (i > -1) {
                this._items.splice(i, 1);
            }
            if (this._count >= 1) {
                this.addToCount(-1);
            }
            this.updateSubjects(this._items);
        }
    }

    update(item: any, compareProp = 'id', updateSubjects = true) {
        if (this._items) {
            const x = this.processItem(item);
            const o = this._items.find(x => x[compareProp] === item[compareProp]);
            if (o) {
                // TODO: implement sortingFn if needed
                Object.assign(o, x);
            } else {
                this._items.push({...x});
            }

            if (updateSubjects) this.updateSubjects(this._items);
        }
    }

    reset() {
        this.ttl = null;
        this._lastRequestedPage = 0;
        if (this._items) {
            this._count = null;
            this._count$.next(null);
            this._items = null;
        }
        this._subjects.forEach(subject => {
            if (subject) subject.complete();
        });
        this._subjects.length = 0;
    }

    invalidate() {
        this.ttl = null;
        if (this._subjects.length) {
            this.refreshItems();
        }
    }

    private addToCount(changeValue: number) {
        if (typeof this._count !== 'number') return;
        this._count += changeValue;
        this._count$.next(this._count);
    }

    private getSubjectForPage(pageNumber) {
        if (pageNumber < 1 && !this._count) {
            return of([]);
        }

        const countPageOnlyIndex = Math.max(pageNumber - 1, 0);
        if (this._subjects[countPageOnlyIndex]) {
            return this._subjects[countPageOnlyIndex];
        }
        this._subjects[countPageOnlyIndex] = new Subject();
        return this._subjects[countPageOnlyIndex];
    }

    private cleanupSubjects() {
        this._subjects.forEach((subj, i) => {
            if (subj && !subjectIsObserved(subj)) {
                subj.complete();
                this._subjects[i] = null;
            }
        });
    }

    private updateSubjects(items) {
        this._subjects.forEach((subj, i) => {
            if (subjectIsObserved(subj)) subj.next(items.slice(0, (i + 1) * this._pageSize));
        });
        this._count$.next(this._count);
    }

    private resetTTL() {
        this.ttl = new Date().getTime() + this._ttlSecs * 1000;
    }

    private cacheItem(item: any, sortingFn?: SortingFn, compareProp = 'id') {
        const x = this.processItem(item);
        const isCached = this._items?.some(x => x[compareProp] === item[compareProp]);
        if (!isCached) {
            let i = sortingFn ? this._items.findIndex(b => sortingFn(x, b) < 0) : this._items.length;
            if (i < 0) i = this._items.length;
            this._items.splice(i, 0, x);
        } else {
            this.update(item, compareProp, false);
        }
    }

    hasObservers() {
        return subjectIsObserved(this._count$) || this._subjects.some(subj => subjectIsObserved(subj));
    }
}
