import {PersonDetailedSerializer} from '../@core/api.service';
import {RelatedPatient} from './patient';
import {assignOrCreate} from './model-base';

export class Person implements PersonDetailedSerializer {
    created_at: Date | string;
    date_ob: Date | string;
    date_of_decease: Date | string;
    first_name: string;
    gender: string;
    id: number;
    last_name: string;
    middle_name: string;
    modified_at: Date | string;
    // @ts-ignore
    patients: RelatedPatient[];
    title_name: string;

    constructor(person: PersonDetailedSerializer) {
        Object.assign(this, person);
        this.patients = person.patients.map(p => assignOrCreate(RelatedPatient, p));
    }
}
