import {Injectable} from '@angular/core';
import {
    APIService,
    FHIRAddressWithRelatedPatientAddressModelSerializer,
    FHIRContactPointWithRelatedPatientContactPointModelSerializer,
    FHIRDeviceSerializer,
    LabResultsSummarySerializer,
    LastVisitTaskSerializer,
    PatientDetailsCreateSerializer,
    PatientErxStatusSerializer,
    PatientMDSSerializer,
    PatientRepresentativeReadSerializer,
    PersonDetailedSerializer,
    ProgressNoteSerializer,
    RelatedPersonSerializer,
    ResidentNoteSerializer,
    VitalSerializer,
} from './api.service';
import {combineLatest, EMPTY, forkJoin, MonoTypeOperatorFunction, Observable, of, Subject, switchMap} from 'rxjs';
import {
    Allergy,
    ClinicalResult,
    criticalValueViewTransformer,
    ImagingResult,
    Immunization,
    InfectionEntry,
    LabTest,
    Order,
    PatientEvent,
    Procedure,
    Task,
    TreatmentEntry,
} from '../models/models';
import {CriticalVitalEntry} from '../models/critical-vital-entry';
import {Assessment} from '../models/assessment';
import {catchError, filter, map, take, tap} from 'rxjs/operators';
import {compareDates, compareEndOrStart, sortByDate, sortByEndOrStart} from '../@theme/helpers';
import {Metric} from '../models/metric';
import {FilterService} from './filter.service';
import {TzDatePipe} from '../@theme/pipes/tz-date.pipe';
import {FacilityChooserService} from './facility-chooser.service';
import {DatePipe} from '@angular/common';
import {UserAuthService} from './user-auth.service';
import {User} from '../models/user';
import {Patient} from '../models/patient';
import {FollowingService} from './following.service';
import {InfectionEntryService} from './infection-entry.service';
import {ItemCache} from './utils/cache-base';
import {getId} from '../utils/type.utils';
import {CheckPatientCache} from './utils/check-patient-cache';
import {TaskProposal} from '../models/task-proposal';
import {TaskProposalService} from './task-proposal.service';
import {Diagnosis} from '../models/diagnosis';
import {assignOrCreate} from '../models/model-base';
import {CacheRepo} from './utils/cache-repo';
import {Person} from '../models/person';
import {Constants} from './constants';
import {PatientVitalSummary} from '../models/vital';
import {PatientInput} from '../portal/bottom-panel/task-editor/task-patient-select/task-patient-select.component';
import {ContentDefinitionKey} from '../definitions/definitions';
import {AccessorUtils} from '../utils/accessor.utils';
import {PatientForVisit} from '../models/patient-for-visit';
import {switchTap} from '../utils/observable.utils';
import {PatientInsurance} from '../models/patient-insurance';

const TTL_SECS = 30 * 60;
const TTL_SHORT = 60;

export type PatientDetailInvalidateKey = keyof PatientDetail['_repos'];

//TODO: fix return type {event: string; patient: Partial<Patient> | number}
export function handlePatientUpdate(patients: () => PatientForVisit[], patientService: PatientService): MonoTypeOperatorFunction<any> {
    return source => source.pipe(
        switchMap(updateEvent => patientService.getPatientDetail(getId(updateEvent.patient)).relatedPatients$),
        map(relatedPatients => patients().filter(patient => relatedPatients.some(p => p.id === patient.id))),
        filter(listPatient => !!listPatient?.length),
        switchMap(listPatient => APIService.PatientCensusViewSet.retrieve(listPatient[0].id).pipe(
            tap(patient => {
                listPatient.forEach(p => {
                    AccessorUtils.clearCache(p);
                    Object.assign(p, patient);
                    Patient.updatePerson(p);
                });
            })
        )),
    );
}

// export function mapTaskToPatientInput(): MonoTypeOperatorFunction<(PatientListWithLastVisitSerializer | PatientListWithLastVisitSimpleSerializer)[]> {
export function mapTaskToPatient<T>(): MonoTypeOperatorFunction<T> {
    return source => source.pipe(
        switchTap(patients => {
            const _patients = (patients?.['results'] || patients);
            const ids = new Set<number>();
            const taskMapping = {
                'last_visit_task_by_physician_id': 'last_visit_task_by_physician',
                'last_visit_task_id': 'last_visit_task',
                'last_visit_task_by_filters_id': 'last_visit_task_by_filters',
                'last_visit_task_routine_id': 'last_visit_task_routine',
                'last_visit_task_skilled_id': 'last_visit_task_skilled',
                'last_visit_task_routine_by_physician_id': 'last_visit_task_routine_by_physician',
                'last_visit_task_skilled_by_physician_id': 'last_visit_task_skilled_by_physician',
            };
            _patients.forEach(patient => {
                Object.keys(taskMapping).forEach(taskKey => {
                    if (patient[taskKey]) ids.add(patient[taskKey]);
                });
            });
            if (!ids.size) return of(patients);
            return APIService.LastVisitTaskViewSet.list({ids: Array.from(ids)}).pipe(
                tap((tasks: LastVisitTaskSerializer[]) => {
                    _patients.forEach(patient => {
                        Object.entries(taskMapping).forEach(([idKey, taskKey]) => {
                            if (!patient[idKey]) return;
                            patient[taskKey] = tasks.find(t => t.id === patient[idKey]);
                        });
                    });
                }),
            );
        })
    );
}

export const PATIENT_DETAIL_REPO_KEYS: {[K in ContentDefinitionKey]?: PatientDetailInvalidateKey} = {
    IMAGING_RESULT: 'imagingResults',
    CLINICAL_RESULT: 'clinicalResults',
    IMMUNIZATION: 'immunizations',
    ALLERGY: 'allergies',
    LAB_TEST: 'labTests',
    PROCEDURE: 'procedures',
    MEDICATION: 'treatmentHistory',
    DIAGNOSIS: 'diagnoses',
    EVENT: 'events',
};

export class PatientDetail {
    constructor(public id: number,
                public ttl: number,
                private api: APIService,
                private filterService: FilterService,
                private fc: FacilityChooserService,
                private datePipe: DatePipe,
                private userAuth: UserAuthService,
                private followingService: FollowingService,
                private constants: Constants,
                public proposalService: TaskProposalService) {
        this._invalidateTime = Date.now() + this.ttl;
        this.userAuth.user.pipe(filter(u => !!u)).subscribe(u => {
            this._user = u;
        });
    }

    get patient$() {
        return this._repos.patient.getData();
    }

    get facility$(): Observable<number> {
        return this.patient$.pipe(take(1), map(x => getId(x.facility)));
    }

    get person$() {
        return this._repos.person.getData();
    }

    get relatedPatients$() {
        return this.person$.pipe(
            switchMap(person => person ?
                of(person.patients) :
                this.patient$.pipe(map(patient => [patient]))
            ),
            take(1),
        );
    }

    get bstPatients$() {
        return this.relatedPatients$.pipe(map(x => x.filter(p => p.isBst)));
    }

    get canEditPatientClinical$() {
        return this.patient$.pipe(take(1), switchMap(patient => this.userAuth.canEditPatientClinical$(patient)));
    }

    get canEditPatientAdmin$() {
        return this.patient$.pipe(take(1), switchMap(patient => this.userAuth.canEditPatientAdmin$(patient)));
    }

    get canEditPatient$() {
        return this.patient$.pipe(take(1), switchMap(patient => this.userAuth.canEditPatient$(patient)));
    }

    get vitalExams$() {
        return this._repos.vitalExams.getData();
    }

    get vitalExams7$() {
        return this._getDetail(this._repos.vitalExams7.getData());
    }

    get vitalExams30$() {
        return this._getDetail(this._repos.vitalExams30.getData());
    }

    get vitalExams60$() {
        return this._getDetail(this._repos.vitalExams60.getData());
    }

    get vitalExams90$() {
        return this._getDetail(this._repos.vitalExams90.getData());
    }

    get vitalExams180$() {
        return this._getDetail(this._repos.vitalExams180.getData());
    }

    get vitals$() {
        return this._getDetail(this.vitals30$);
    }

    get vitals7$() {
        return this._getDetail(this._repos.vitals7.getData());
    }

    get vitals30$() {
        return this._getDetail(this._repos.vitals30.getData());
    }

    get vitals60$() {
        return this._getDetail(this._repos.vitals60.getData());
    }

    get vitals90$() {
        return this._getDetail(this._repos.vitals90.getData());
    }

    get vitals180$() {
        return this._getDetail(this._repos.vitals180.getData());
    }

    get criticalVitals$() {
        return this._getDetail(this._repos.criticalVitals.getData());
    }

    get infectionEntries$() {
        return this._repos.infectionEntries.getData();
    }

    get events$() {
        return this._getDetail(this._repos.events.getData());
    }

    get fallEvents$() {
        return this.events$;
    }

    get fallEventsSevenDays$() {
        return this.fallEvents$.pipe(
            filter(x => !!x),
            map((fallEvents: PatientEvent[]) => fallEvents.filter((pe => pe && pe.date ? ((Date.now() - 1000 * 60 * 60 * 24 * 7) < Date.parse(pe.date as string)) : false)))
        );
    }

    get orders$() {
        return this._getDetail(this._repos.orders.getData());
    }

    get residentNotes$() {
        return this._repos.residentNotes.getData();
    }

    get progressNotes$() {
        return this._repos.progressNotes.getData();
    }

    get assessments$() {
        return this._getDetail(this._repos.assessments.getData());
    }

    get mdsResults$() {
        return this._getDetail(this._repos.mdsResults.getData());
    }

    get diagnoses$() {
        return this._getDetail(this._repos.diagnoses.getData());
    }

    get imagingResults$() {
        return this._getDetail(this._repos.imagingResults.getData());
    }

    get treatmentHistory$() {
        return this._getDetail(this._repos.treatmentHistory.getData());
    }

    get labTests$() {
        return this._getDetail(this._repos.labTests.getData());
    }

    get labSummary$() {
        return this._getDetail(this._repos.labSummary.getData());
    }

    get taskProposals$() {
        return this._repos.taskProposals.getData();
    }

    get lastHPTask$() {
        return this._repos.lastHPTask.getData();
    }

    get lastRoutineTask$() {
        return this._repos.lastRoutineTask.getData();
    }

    get devices$() {
        return this._repos.devices.getData();
    }

    get immunizations$() {
        return this._repos.immunizations.getData();
    }

    get procedures$() {
        return this._repos.procedures.getData();
    }

    get clinicalResults$() {
        return this._repos.clinicalResults.getData();
    }

    get relatedPersons$() {
        return this._getDetail(this._repos.relatedPersons.getData());
    }

    get allergies$() {
        return this._repos.allergies.getData();
    }

    get noKnownAllergies$() {
        return combineLatest([this.patient$, this.allergies$]).pipe(
            map(([patient, allergies]) => !allergies?.length && patient.no_known_allergies),
        );
    }

    get representatives$() {
        return this._repos.representatives.getData();
    }

    private _getVitalExamsRepo(days: number) {
        return new CacheRepo<PatientVitalSummary>({
            apiEndpoint: this.api.PatientViewSet.last_vital_examinations(this.id, {days, before_discharge: true})
                .pipe(
                    map(x => x.results),
                    map(results => {
                        const vitalSummaries = [
                            {name: 'Blood Pressure', unit: 'mmHg', key: 'blood_pressure', results: []},
                            {name: 'Pulse', unit: 'bpm', key: 'pulse', results: []},
                            {name: 'Temperature', unit: 'F', key: 'temperature', results: [], unitConverter: {C: (value: number) => value * 9 / 5 + 32}},
                            {name: 'Respirations', unit: 'bpm', key: 'respirations', results: []},
                            {name: 'Blood Sugar', unit: 'mg/dL', key: 'blood_sugar', results: []},
                            {name: 'Height', unit: 'in', key: 'height', results: [], unitConverter: {cm: (value: number) => value / 2.54}},
                            {name: 'Weight', unit: 'lb', key: 'weight', results: [], unitConverter: {kg: (value: number) => value * 2.20462}},
                            {name: 'BMI', unit: '', key: 'bmi', results: []},
                            {name: 'Pediatric Head Occipital-frontal Circumference Percentile', unit: '%', key: 'pediatric_head_circumference_percentile', results: []},
                            {name: 'Head Circumference', unit: '%', key: 'head_circumference', results: []},
                            {name: 'O2 Saturation', unit: '%', key: 'o2_saturation', results: []},
                            {name: 'O2 Concentration (FiO2)', unit: '%', key: 'inhaled_oxygen_concentration', results: []},
                            {name: 'O2 Flow Rate', unit: 'L/min', key: 'inhaled_oxygen_flow_rate', results: []},
                            {name: 'Pediatric BMI for Age Percentile', unit: '%', key: 'pediatric_bmi_for_age_percentile', results: []},
                            {name: 'Pediatric Weight for Age Percentile', unit: '%', key: 'pediatric_weight_for_height_percentile', results: []},
                        ] as PatientVitalSummary[];
                        results.forEach(result => {
                            vitalSummaries.forEach(vitalSign => {
                                if (result[`${vitalSign.key}_value`] || result[`${vitalSign.key}_value`] === 0) {
                                    vitalSign.results.push({
                                        value: (vitalSign.unitConverter && result[`${vitalSign.key}`+'_unit'] in vitalSign.unitConverter) ? vitalSign.unitConverter[result[`${vitalSign.key}`+'_unit']](result[`${vitalSign.key}_value`]) : result[`${vitalSign.key}_value`],
                                        value_secondary: result[`${vitalSign.key}_value_secondary`],
                                        recorded: result.recorded,
                                    } as VitalSerializer);
                                }
                            });
                        });
                        return vitalSummaries.filter(vitalSummary => vitalSummary.results.length > 0);
                    }),
                    map(
                        vitalSummaries => {
                            const filteredVitalSummaries = vitalSummaries.filter(vitalSummary => vitalSummary.results.length > 0);
                            for (const vitalSummary of filteredVitalSummaries) {
                                if (vitalSummary.results.length > 0) {
                                    vitalSummary.results.reverse();
                                    vitalSummary.metric = new Metric(
                                        TzDatePipe.transform(this.fc, this.datePipe, vitalSummary.results[vitalSummary.results.length - 1].recorded, 'MMM d, y'),
                                        vitalSummary.results[vitalSummary.results.length - 1].value,
                                        {
                                            series: [{name: vitalSummary.name,
                                                series: vitalSummary.results.filter(x => typeof x.value === 'number').map(x => ({
                                                    name: new Date(x.recorded).setUTCHours(12),
                                                    value: x.value,
                                                }))}],
                                        }
                                    );
                                }
                                if (vitalSummary.results.some(x => typeof x.value_secondary === 'number')) {
                                    vitalSummary.metric_secondary = new Metric(
                                        null,
                                        vitalSummary.results[vitalSummary.results.length - 1].value_secondary,
                                        {
                                            series: [{
                                                name: vitalSummary.name,
                                                series: vitalSummary.results.filter(x => typeof x.value_secondary === 'number').map(x => ({
                                                    name: new Date(x.recorded).setUTCHours(12),
                                                    value: x.value_secondary,
                                                })),
                                            }],
                                        }
                                    );
                                }
                            }
                            return filteredVitalSummaries;
                        }
                    )
                ),
            ttlSecs: TTL_SECS,
        });
    }

    private _getVitalsRepo(days: number) {
        return new CacheRepo({
            apiEndpoint: forkJoin([
                this.filterService.medicalResultTypes$,
                this.api.PatientViewSet.last_vitals(this.id, {days, processed: false}) as Observable<{discharged: string; results: VitalSerializer[]}>,
            ]).pipe(map(([tr, vr]) => {
                const vitals: PatientVitalSummary[] = tr
                    .map(type => ({
                        results: vr.results.filter(r => r.medical_result_type === type.id)
                            .sort((a, b) => new Date(a.recorded).getTime() - new Date(b.recorded).getTime()),
                        ...type,
                    }))
                    .filter(type => !['Breakfast', 'Lunch', 'Dinner', 'AM Snack', 'PM Snack', 'Bedtime Snack', 'Fluids', 'Urine'].includes(type.name) && type.results && type.results.length);
                vitals.forEach(type => {
                    type.metric = new Metric(TzDatePipe.transform(this.fc, this.datePipe, type.results[type.results.length - 1].recorded, 'MMM d, y HH:mm'), type.results[type.results.length - 1].value, {
                        series: [{
                            name: type.name,
                            series: type.results.filter(x => typeof x.value === 'number').map(x => ({
                                name: new Date(x.recorded),
                                value: x.value,
                            })),
                        }],
                    });
                    if (type.results.some(x => typeof x.value_secondary === 'number')) {
                        type.metric_secondary = new Metric(null, type.results[type.results.length - 1].value_secondary, {
                            series: [{
                                name: type.name,
                                series: type.results.filter(x => typeof x.value_secondary === 'number').map(x => ({
                                    name: new Date(x.recorded),
                                    value: x.value_secondary,
                                })),
                            }],
                        });
                    }
                });

                return vitals;
            })),
            ttlSecs: TTL_SECS,
        });
    }

    get addresses$(): Observable<FHIRAddressWithRelatedPatientAddressModelSerializer[]> {
        return this._repos.address.getData();
    }

    get contactPoints$(): Observable<FHIRContactPointWithRelatedPatientContactPointModelSerializer[]> {
        return this._repos.contactInfo.getData();
    }

    get insurance$() {
        return this._repos.insurance.getData();
    }

    get ssn$(): Observable<PatientDetailsCreateSerializer> {
        return this._repos.ssn.getData();
    }

    get eRxStatus$() {
        return this._repos.eRxStatus.getData();
    }

    clinicalNotesById$(ids: number[]) {
        return this._repos.progressNotes.getData().pipe(map(pn => pn.filter(note => ids.includes(note.id))));
    }

    _repos = {
        patient: new ItemCache<Patient>({
            apiEndpoint: combineLatest([
                this.api.PatientViewSet.retrieve_detail(this.id).pipe(map(p => new Patient(p))),
                this.followingService.getPatientIsFollowed(this.id),
            ]).pipe(map(([p, followed]) => {
                p.is_followed = followed;
                return p;
            })),
            ttlSecs: TTL_SECS,
        }),
        person: new ItemCache<PersonDetailedSerializer, Person>({
            apiEndpoint: APIService.PersonViewSet.list({patient: this.id}).pipe(map(x => x[0] || null)),
            processItem: x => x && new Person(x),
            ttlSecs: TTL_SECS,
        }),
        relatedPersons: new CacheRepo<RelatedPersonSerializer, RelatedPersonSerializer>({
            apiEndpoint: APIService.RelatedPersonModelViewSet.list({patient: this.id}),
            user: this.userAuth.user,
            processItem: x => {
                x.given_names = x.given_names?.join(' ') as any;
                x.phones.forEach(phone => phone['displayPhoneUse'] = phone.use === 'home' ? 'primary' : phone.use);
                return x;
            },
        }),
        // allergies: new CacheRepo<AllergyPatientReadSerializer>({
        //     apiEndpoint: APIService.PatientAllergyViewSet.list({patient: this.id}),
        //     ttlSecs: TTL_SECS,
        // processItem: allergy => {
        //     return {
        //         ...allergy,
        //         allergy_type_snomed_concept: assignOrCreate(SnomedCode, allergy.allergy_type_snomed_concept)
        //     };
        // },
        // }),
        allergies: new CacheRepo<Allergy>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_CLINICAL_GENERAL', 'PERMISSION_WRITE_PATIENT_CLINICAL'],
            apiEndpoint: Allergy.list({patient: this.id}),
            sortingFn: (a: Allergy, b: Allergy) => {
                if (a.isActive !== b.isActive) return a.isActive ? -1 : 1;
                return compareEndOrStart(a, b);
            },
            ttlSecs: TTL_SECS,
        }),
        vitalExams: this._getVitalExamsRepo(30),
        vitalExams7: this._getVitalExamsRepo(7),
        vitalExams30: this._getVitalExamsRepo(30),
        vitalExams60: this._getVitalExamsRepo(60),
        vitalExams90: this._getVitalExamsRepo(90),
        vitalExams180: this._getVitalExamsRepo(180),
        vitals7: this._getVitalsRepo(7),
        vitals30: this._getVitalsRepo(30),
        vitals60: this._getVitalsRepo(60),
        vitals90: this._getVitalsRepo(90),
        vitals180: this._getVitalsRepo(180),
        criticalVitals: new ItemCache<CriticalVitalEntry>({
            apiEndpoint: forkJoin([
                this.filterService.medicalResultTypes$,
                this.api.CriticalValueView.list({patient: this.id, days: 7}),
            ]).pipe(
                criticalValueViewTransformer,
                map(([tr, cvr]) => cvr.length ? new CriticalVitalEntry(cvr[0], false) : null),
            ),
            ttlSecs: TTL_SECS,
        }),
        infectionEntries: new CacheRepo<InfectionEntry>({
            apiEndpoint: this.api.InfectionEntryViewSet.list({patient: this.id}).pipe(
                map(ie => ie.results
                    .filter(x => !!x.infection_information && !!x.infection_information.length)
                    .map(x => new InfectionEntry(x))
                    .sort((a, b) => (new Date(b.start_date).getTime() - new Date(a.start_date).getTime()))
                ),
                filter(entries => !!entries),
            ),
            ttlSecs: TTL_SECS,
        }),
        events: new CacheRepo<PatientEvent>({
            apiEndpoint: PatientEvent.list({patient: this.id}).pipe(map(pe => pe.sort(PatientEvent.compareSort))),
            ttlSecs: TTL_SECS,
        }),
        orders: new CacheRepo<Order>({
            apiEndpoint: this.api.OrderViewSet.list({patient: this.id}).pipe(
                map(pe => sortByEndOrStart(pe.results.map(x =>
                    assignOrCreate(Order, Object.assign(x, {physician: x.physician && new User(x.physician)}))), 'start_date', 'end_date')
                )
            ),
            ttlSecs: TTL_SECS,
        }),
        residentNotes: new CacheRepo<ResidentNoteSerializer>({
            apiEndpoint: this.api.ResidentNoteViewSet.list({patient: this.id}).pipe(
                map(pe => sortByDate(pe, 'date'))
            ),
            ttlSecs: TTL_SECS,
        }),
        progressNotes: new CacheRepo<ProgressNoteSerializer>({
            apiEndpoint: this.api.ProgressNoteViewSet.list({patient: this.id}).pipe(
                map(pe => sortByDate(pe, 'date'))
            ),
            ttlSecs: TTL_SECS,
        }),
        assessments: new ItemCache<any, Assessment>({
            apiEndpoint: this.api.PatientViewSet.assessment(this.id).pipe(
                map(a => (a as Assessment))
            ),
            ttlSecs: TTL_SECS,
        }),
        mdsResults: new ItemCache<PatientMDSSerializer>({
            apiEndpoint: this.api.PatientViewSet.mds(this.id),
            ttlSecs: TTL_SECS,
        }),
        diagnoses: new CacheRepo<Diagnosis>({
            apiEndpoint: this.api.DiagnosisViewSet.list_detail({patient: this.id}).pipe(
                map(res => sortByDate(res.map(x => assignOrCreate(Diagnosis, x)), 'date_diagnosed'))
            ),
            ttlSecs: TTL_SECS,
        }),
        // snomedDiagnoses: new CacheRepo<SnomedDiagnosisSerializer>({
        //     apiEndpoint: this.api.SnomedDiagnosisViewSet.list({patient: this.id}),
        //     ttlSecs: TTL_SECS,
        // }),
        treatmentHistory: new CacheRepo<TreatmentEntry>({
            apiEndpoint: TreatmentEntry.list({patient: this.id}),
            ttlSecs: TTL_SECS,
        }),
        labTests: new CacheRepo<LabTest>({
            apiEndpoint: LabTest.list({lab_report__patient_id: this.id}).pipe(map(lt => lt || [])),
            ttlSecs: TTL_SECS,
        }),
        labSummary: new ItemCache<LabResultsSummarySerializer>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_LAB_REPORTS', 'PERMISSION_READ_CLINICAL_GENERAL'],
            apiEndpoint: this.api.LabResultViewSet.recent_summary({patient: this.id}),
            ttlSecs: TTL_SECS,
        }),
        taskProposals: new CacheRepo<TaskProposal>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_VISITS', 'PERMISSION_READ_TASK_PROPOSALS'],
            apiEndpoint: this.proposalService.getProposals$({patient: this.id}),
            ttlSecs: TTL_SECS,
        }),
        lastHPTask: new ItemCache<Task>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_TASKS'],
            apiEndpoint: Task.list({patient: this.id, type: 'VISIT_HP', page_size: 1, page: 1, status: 'SAVED'}).pipe(map(x => x[0])),
            ttlSecs: TTL_SHORT,
        }),
        lastRoutineTask: new ItemCache<Task>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_TASKS'],
            apiEndpoint: Task.list({patient: this.id, type: 'VISIT_ROUTINE', page_size: 1, page: 1, status: 'SAVED'}).pipe(map(x => x[0])),
            ttlSecs: TTL_SHORT,
        }),
        devices: new CacheRepo<FHIRDeviceSerializer>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_CLINICAL_GENERAL', 'PERMISSION_WRITE_PATIENT_CLINICAL'],
            apiEndpoint: this.api.FHIRDeviceViewSet.list({patient: this.id}),
            sortingFn: (a: FHIRDeviceSerializer, b: FHIRDeviceSerializer) => a.is_active && !b.is_active ? -1 : 1,
            ttlSecs: TTL_SECS,
        }),
        immunizations: new CacheRepo<Immunization>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_CLINICAL_GENERAL', 'PERMISSION_WRITE_PATIENT_CLINICAL', 'PERMISSION_READ_IMMUNIZATIONS', 'PERMISSION_WRITE_IMMUNIZATIONS'],
            apiEndpoint: Immunization.list({patient: this.id}),
            sortingFn: (a: Immunization, b: Immunization) => {
                if ([a.statusColorClass, b.statusColorClass].includes('danger')) return a.statusColorClass === 'danger' ? 1 : -1;
                return compareEndOrStart(a, b, 'date', 'expiration');
            },
            // adding ttlSecs prevents immediate update of immunizations after adding or editing
            ttlSecs: TTL_SECS,
        }),
        procedures: new CacheRepo<Procedure>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_CLINICAL_GENERAL', 'PERMISSION_WRITE_PATIENT_CLINICAL'],
            apiEndpoint: Procedure.list({patient: this.id}),
            sortingFn: (a: Procedure, b: Procedure) => compareDates(a.performed, b.performed),
            ttlSecs: TTL_SECS,
        }),
        clinicalResults: new CacheRepo<ClinicalResult>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_CLINICAL_GENERAL', 'PERMISSION_WRITE_PATIENT_CLINICAL'],
            apiEndpoint: ClinicalResult.list({patient: this.id}),
            sortingFn: (a: ClinicalResult, b: ClinicalResult) => {
                if (a.isRelevant !== b.isRelevant) return a.isRelevant ? -1 : 1;
                return compareDates(a.date, b.date);
            },
            ttlSecs: TTL_SECS,
        }),
        imagingResults: new CacheRepo<ImagingResult>({
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_CLINICAL_GENERAL', 'PERMISSION_WRITE_PATIENT_CLINICAL'],
            apiEndpoint: ImagingResult.list({patient: this.id}),
            sortingFn: (a: ImagingResult, b: ImagingResult) => {
                if (a.isRelevant !== b.isRelevant) return a.isRelevant ? -1 : 1;
                return compareDates(a.date, b.date);
            },
            ttlSecs: TTL_SECS,
        }),
        address: new CacheRepo<FHIRAddressWithRelatedPatientAddressModelSerializer>({
            apiEndpoint: this.api.PatientViewSet.addresses(this.id),
            ttlSecs: TTL_SECS,
        }),
        contactInfo: new CacheRepo<FHIRContactPointWithRelatedPatientContactPointModelSerializer>({
            apiEndpoint: this.api.PatientViewSet.contact_points(this.id),
            ttlSecs: TTL_SECS,
        }),
        insurance: new ItemCache<PatientInsurance>({
            user: this.userAuth.user,
            permissions: [
                'PERMISSION_WRITE_PATIENT_ADMIN',
                'PERMISSION_WRITE_PATIENT_CLINICAL',
                'PERMISSION_READ_PATIENT_INSURANCE',
                'PERMISSION_WRITE_PATIENT_INSURANCE',
            ],
            apiEndpoint: this.api.PatientInsuranceViewSet.list({patient: this.id}).pipe(
                catchError(() => EMPTY),
                map(res => res[0] || null),
                map(res => res && new PatientInsurance(res)),
            ),
            ttlSecs: TTL_SECS,
        }),
        representatives: new CacheRepo<PatientRepresentativeReadSerializer>({
            apiEndpoint: this.api.PatientRepresentativeViewSet.list({patient: this.id}),
            ttlSecs: TTL_SECS,
            user: this.userAuth.user,
            permissions: ['PERMISSION_READ_PATIENT_REPRESENTATIVES'],
        }),
        ssn: new ItemCache<PatientDetailsCreateSerializer>({
            apiEndpoint: this.api.SSNModelViewSet.retrieve(this.id),
            ttlSecs: TTL_SECS,
        }),
        eRxStatus: new ItemCache<PatientErxStatusSerializer>({
            apiEndpoint: this.api.PatientERXViewSet.patient_status_in_erx(this.id).pipe(catchError(() => of(null))),
            ttlSecs: TTL_SECS,
        }),
    };

    invalidate(key?: PatientDetailInvalidateKey, fetchIfObserved = true) {
        if (key) {
            this._invalidateKey(key, fetchIfObserved);
        } else {
            Object.values(this._repos).forEach((x: CacheRepo<any> | ItemCache<any>) => x.invalidate(fetchIfObserved));
        }
    }

    private _invalidateKey(key: PatientDetailInvalidateKey, fetchIfObserved = true) {
        const repo: CacheRepo<any> | ItemCache<any> = this._repos[key] || this._repos[key.slice(0, key.length - 1)];
        if (repo) repo.invalidate(fetchIfObserved);
    }

    headerIsRestricted(): Observable<boolean> {
        return this.isViewRestricted().pipe(map(x => x && this._user.permissions.includes('RESTRICTION_PATIENT_DETAILS_ASSIGNED')));
    }

    isPatientEditable(): Observable<boolean> {
        return this.patient$.pipe(
            take(1),
            map(p => p.is_patient_assigned_to_physician_team || this._user.isSuperAdmin)
        );
    }

    private _invalidateTime: number;
    private _user: User;

    isViewRestricted(): Observable<boolean> {
        return this.patient$.pipe(
            take(1),
            map(p => {
                if (
                    this._user.permissions.includes('PERMISSION_PATIENT_DETAILS') ||
                    p.user_is_md ||
                    getId(this._user.customer) === getId(p.customer)
                ) {
                    return false;
                }
                if (this._user.permissions.includes('RESTRICTION_PATIENT_DETAILS_CCM_ELIGIBLE')) {
                    return !(p.details?.calculated_ccm_eligible ||
                        p.chronic_diagnoses.length > 2 &&
                        !['Medicare A', 'Hospice'].includes(p.payer) &&
                        p.payers?.some(x => ['Medicare A', 'Medicare B'].includes(x.name))
                    );
                }
                if (this._user.permissions.includes('RESTRICTION_PATIENT_DETAILS_ASSIGNED')) {
                    return !p.is_patient_assigned_to_physician_team;
                }
                return false;
            }),
        );
    }

    private _getDetail<T>(observable: Observable<T>): Observable<T> {
        return observable;
        // return this.isViewRestricted().pipe(switchMap(r => r ? of(<any>[]) : observable));
    }
}

@Injectable({
    providedIn: 'root',
})
export class PatientService {
    newPatient$ = new Subject<{hash: string; patient: Patient; sourcePatient?: PatientInput}>();
    patientUpdates$ = new Subject<{event: string; patient: Partial<Patient> | number}>();

    getPatientDetail(id: number): PatientDetail {
        if (PatientService._patientCache[id]) {
            return PatientService._patientCache[id];
        }
        const patientDetail = new PatientDetail(
            id,
            this._ttl,
            this.api,
            this.filterService,
            this.fc,
            this.datePipe,
            this.userAuth,
            this.followingService,
            this.constants,
            this.proposalService,
        );
        PatientService._patientCache[id] = patientDetail;
        return patientDetail;
    }

    static invalidate(id?: number, subjKey?: PatientDetailInvalidateKey) {
        if (id) {
            if (PatientService._patientCache[id]) PatientService._patientCache[id].invalidate(subjKey);
            return;
        }

        const previousCache = PatientService._patientCache;
        PatientService._patientCache = {};
        Object.values(previousCache).forEach(x => x.invalidate(subjKey));
    }

    invalidate(id?: number, subjKey?: PatientDetailInvalidateKey) {
        PatientService.invalidate(id, subjKey);
    }

    invalidateKey(subjKey: PatientDetailInvalidateKey) {
        Object.values(PatientService._patientCache).forEach(x => x.invalidate(subjKey));
    }

    private _ttl = TTL_SECS * 1000; // TTL in milliseconds

    static _patientCache: {[key: number]: PatientDetail} = {};

    static checkCache(key: string) {
        if (CheckPatientCache.queue[key]) {
            const queue = CheckPatientCache.queue[key];
            CheckPatientCache.queue[key] = null;

            Object.keys(queue).forEach(patientId => {
                const pd: PatientDetail = this._patientCache[patientId];
                const repo: ItemCache<any> | CacheRepo<any> = pd && pd._repos[key];

                if (!repo || !repo.data) return;

                queue[patientId].forEach(objectId => {
                    if (repo instanceof ItemCache) {
                        if (repo.data.id != objectId) repo.invalidate();
                    } else if (repo instanceof CacheRepo) {
                        if (!repo.data.some(x => x.id == objectId)) repo.invalidate();
                    }
                });
            });
        }
    }

    constructor(private api: APIService,
                private infectionEntriesService: InfectionEntryService,
                private filterService: FilterService,
                private fc: FacilityChooserService,
                private datePipe: DatePipe,
                private userAuth: UserAuthService,
                private followingService: FollowingService,
                private constants: Constants,
                public proposalService: TaskProposalService) {
        this.userAuth.user.subscribe(() => this.invalidate());

        this.infectionEntriesService.updateEntries$.subscribe(newEntries => {
            newEntries.forEach(entry =>
                this.invalidate(
                    typeof entry.patient === 'number' ? entry.patient : entry.patient.id,
                    'infectionEntries'
                )
            );
        });

        this.proposalService.invalidatePatient$.subscribe(x => this.invalidate(x.id, x.patientDetailKey));

        CheckPatientCache.checkKey$.subscribe(key => PatientService.checkCache(key));
    }
}
