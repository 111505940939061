import {Injectable} from '@angular/core';
import {
    APIService,
    PatientCensusSerializer,
    PatientDetailSerializer,
    PatientListSerializer,
    PatientSerializer,
    TaskSimpleSerializer,
    UserSerializer,
} from './api.service';
import {UserAuthService} from './user-auth.service';
import {User} from '../models/user';
import {EMPTY, Observable} from 'rxjs';
import {ItemCache, RepoAction} from './utils/cache-base';
import {Patient} from '../models/patient';
import {catchError, map, share, take} from 'rxjs/operators';
import {Task} from '../models/models';
import {getId} from '../utils/type.utils';
import {ToastService} from './toast.service';
import {assignOrCreate} from '../models/model-base';
import {PagedCacheRepo, PagedCacheRepoArgs} from './utils/paged-cache-repo';

@Injectable({
    providedIn: 'root',
})
export class FollowingService {
    notePageSize = 20;
    private _followingPermissions = ['PERMISSION_FOLLOW_PATIENTS'];
    private _user: User;
    private _patientsFollowed = new PagedCacheRepo<PatientCensusSerializer, Patient>({
        constructorClass: Patient,
        apiFunction: this.api.FollowedPatientViewSet.list,
        pageSize: 9999,
        permissions: this._followingPermissions,
        user: this.userAuth.user,
    });

    private _tasksArgs: PagedCacheRepoArgs<TaskSimpleSerializer> = {
        apiFunction: this.api.TaskViewSet.list,
        pageSize: this.notePageSize,
        constructorClass: Task,
        sortingFn: Task.compareSort,
        permissions: this._followingPermissions,
        user: this.userAuth.user,
    };

    private _assignedTasks = {
        unresolved: new PagedCacheRepo<TaskSimpleSerializer, Task>(this._tasksArgs),
        resolved: new PagedCacheRepo<TaskSimpleSerializer, Task>(this._tasksArgs),
    };
    private _createdTasks = {
        unresolved: new PagedCacheRepo<TaskSimpleSerializer, Task>(this._tasksArgs),
        untagged: new PagedCacheRepo<TaskSimpleSerializer, Task>(this._tasksArgs),
        resolved: new PagedCacheRepo<TaskSimpleSerializer, Task>(this._tasksArgs),
    };

    private _draftTaskCount = new ItemCache({apiEndpoint: APIService.TaskViewSet.list({by_physician_team: true, draft: true, page_size: 1, page: 1}).pipe(map(x => x.count))});
    private _pendingTaskCount = new ItemCache({apiEndpoint: APIService.TaskViewSet.list({billing_status: 'BILLING_PENDING', page_size: 1, page: 1}).pipe(map(x => x.count))});

    private _taskCount$ = {
        assigned: {
            unresolved: null,
            resolved: null,
        },
        created: {
            unresolved: null,
            untagged: null,
            resolved: null,
        },
        followed: null,
    };
    private _followedPatientsTasks = new PagedCacheRepo<TaskSimpleSerializer, Task>(this._tasksArgs);

    constructor(private userAuth: UserAuthService,
                private api: APIService,
                private toastService: ToastService) {
        this.userAuth.user.subscribe(u => {
            this._user = u;
            this.reset();
            if (this._user) {
                this._followedPatientsTasks.filters = {patient__followed_by: this._user.id};
                this._assignedTasks.unresolved.filters = {assigned_users__user: this._user.id, assigned_users__done: '0'};
                this._assignedTasks.resolved.filters = {assigned_users__user: this._user.id, assigned_users__done: 1};
                this._createdTasks.unresolved.filters = {created_by_me: 1, assigned_users__done: '0'};
                this._createdTasks.resolved.filters = {created_by_me: 1, assigned_users__done: 1};
                this._createdTasks.untagged.filters = {created_by_me: 1, without_assigned_users: 1};
            }
        });
    }

    loadNextNotePage(noteType: 'assigned' | 'created' | 'followed', noteStatus: 'resolved' | 'untagged' | 'unresolved'): (page: number) => Observable<Task[]> {
        if (noteType === 'assigned') {
            switch (noteStatus) {
                case 'resolved':
                    return page => this._assignedTasks.resolved.getData(page);
                case 'unresolved':
                    return page => this._assignedTasks.unresolved.getData(page);
                default:
                    return null;
            }
        } else if (noteType === 'created') {
            switch (noteStatus) {
                case 'resolved':
                    return page => this._createdTasks.resolved.getData(page);
                case 'unresolved':
                    return page => this._createdTasks.unresolved.getData(page);
                case 'untagged':
                    return page => this._createdTasks.untagged.getData(page);
                default:
                    return null;
            }
        } else if (noteType === 'followed') {
            return page => this._followedPatientsTasks.getData(page);
        }
    }

    onTaskUpdate(update: {event: RepoAction; task: Partial<Task>}) {
        this._draftTaskCount.invalidate();
        this._pendingTaskCount.invalidate();

        const tid = update.task ? update.task.id : null;
        if (!tid) return;

        const repos: PagedCacheRepo<TaskSimpleSerializer, Task>[] = [];
        const resolved = FollowingService.taskIsResolved((update.task as Task));

        if (update.task.assigned_users?.some(x => x.user.id === this._user.id)) {
            repos.push(this._assignedTasks[resolved ? 'resolved' : 'unresolved']);
        }
        if (getId(update.task.user) === this._user.id) {
            const untagged = !update.task.assigned_users?.length;
            repos.push(this._createdTasks[untagged ? 'untagged' : resolved ? 'resolved' : 'unresolved']);
        }
        this.getPatientIsFollowed(getId(update.task.patient)).pipe(take(1)).subscribe(followed => {
            if (followed) repos.push(this._followedPatientsTasks);

            repos.forEach(repo => update.event === 'add' ? repo.add(update.task, Task.compareSort) : repo[update.event]((update.task as Task)));
        });
    }

    getPatientsFollowed(): Observable<Patient[]> {
        return this._patientsFollowed.getData(1);
    }

    getPatientsFollowedCount$(): Observable<number> {
        return this._patientsFollowed.count$;
    }

    getPatientIsFollowed(patient: number | Patient | PatientSerializer | PatientDetailSerializer | PatientListSerializer): Observable<boolean> {
        const pid = getId(patient);
        return this._patientsFollowed.getData(1).pipe(map(patients => patients ? patients.some(p => p.id == pid) : false));
    }

    setFollow(patient: Patient, follow = true) {
        this.api.PatientViewSet[follow ? 'follow' : 'unfollow'](patient.id).subscribe((res: any) => {
            patient.is_followed = res.followed;
            this.toastService.success(`Patient successfully ${res.followed ? 'Followed' : 'Unfollowed'}`);
            // TODO: didn't implement proper add & remove logic because followed patients list uses PatientCensusView
            if (!res.followed) this._patientsFollowed.remove(patient);
            else this._patientsFollowed.invalidate();
            this._followedPatientsTasks.invalidate();
        }, () => this.toastService.error('An error occurred while trying to save the following state'));
    }

    getAssignedTasks(type: 'unresolved' | 'resolved' = 'unresolved'): Observable<Task[]> {
        return this._assignedTasks[type].getData(1);
    }

    getAssignedTaskCount(type: 'unresolved' | 'resolved'): Observable<number> {
        if (!this._taskCount$.assigned[type]) {
            this._taskCount$.assigned[type] = this._assignedTasks[type].count$;
        }
        return this._taskCount$.assigned[type];
    }

    getCreatedTasks(type: 'unresolved' | 'untagged' | 'resolved' = 'unresolved'): Observable<Task[]> {
        return this._createdTasks[type].getData(1);
    }

    getCreatedTaskCount(type: 'unresolved' | 'untagged' | 'resolved'): Observable<number> {
        if (!this._taskCount$.created[type]) {
            this._taskCount$.created[type] = this._createdTasks[type].count$;
        }
        return this._taskCount$.created[type];
    }

    getFollowedPatientsTasks(): Observable<Task[]> {
        return this._followedPatientsTasks.getData(1);
    }

    getFollowedPatientsTaskCount(): Observable<number> {
        if (!this._taskCount$.followed) this._taskCount$.followed = this._followedPatientsTasks.count$;
        return this._taskCount$.followed;
    }

    getDraftCount() {
        return this._draftTaskCount.getData();
    }

    getPendingNoteCount() {
        return this._pendingTaskCount.getData();
    }

    markTaskResolved(task: Task, resolved = true) {
        let obs$: Observable<TaskSimpleSerializer>;

        const currentAssigned = task.assigned_users?.find(x => x.user.id === this._user.id);
        if (!currentAssigned) {
            obs$ = APIService.TaskAssignedUserViewSet.assign_user({task: task.id, user: this._user.id, done: resolved}).pipe(
                catchError(() => this.toastService.error("Couldn't mark as resolved because we were unable to assign current user to the task") && EMPTY),
            );
        } else if (currentAssigned.id) {
            obs$ = this.api.TaskAssignedUserViewSet.partial_update(currentAssigned.id, {done: resolved}).pipe(
                catchError(() => this.toastService.error('An error occurred while trying to save the tasks resolved state') && EMPTY),
            );
        } else {
            this.toastService.error("Couldn't find identifier");
            obs$ = EMPTY;
        }
        const o$ = obs$.pipe(share(), map(t => assignOrCreate(Task, t)));
        o$.subscribe(t => this.toggleTaskResolved(t, resolved));

        return o$;
    }

    toggleTaskResolved(task: Task, resolved: boolean) {
        if (task.user.id === this._user.id) {
            this._createdTasks.unresolved.remove(task);
            this._createdTasks.resolved.add(task, Task.compareSort);
        }

        if (resolved) {
            this._assignedTasks.unresolved.remove(task);
            this._assignedTasks.resolved.add(task, Task.compareSort);
        } else {
            this._assignedTasks.unresolved.add(task, Task.compareSort);
            this._assignedTasks.resolved.remove(task);
        }
        this._followedPatientsTasks.update(task);
    }

    static taskIsResolved(task: Task): boolean {
        return task.assigned_users?.some(x => x.done) || false;
    }

    taskIsAssignedToUser(task: Task, user: UserSerializer = this._user): boolean {
        return task.assigned_users?.some(x => x.user.id === user.id) || false;
    }

    static taskIsResolvedByUser(task: Task, user: UserSerializer): boolean {
        return task.assigned_users?.some(x => x.user.id === user.id && x.done) || false;
    }

    taskIsResolvedByUser(task: Task, user: UserSerializer = this._user): boolean {
        return FollowingService.taskIsResolvedByUser(task, user);
    }

    reset() {
        this._patientsFollowed.reset();
        this._assignedTasks.unresolved.reset();
        this._assignedTasks.resolved.reset();
        this._createdTasks.unresolved.reset();
        this._createdTasks.resolved.reset();
        this._createdTasks.untagged.reset();
        this._followedPatientsTasks.reset();
    }

    invalidateRepo() {
        this._assignedTasks.unresolved.invalidate();
        this._assignedTasks.resolved.invalidate();
    }
}
