import {ModelBase} from './model-base';
import {getId, Modify} from '../utils/type.utils';
import {APIService, PhysicianNameSerializer, TaskProposalForPatientsSerializer, TaskProposalSerializer} from '../@core/api.service';
import {Visit, VISIT_TYPES, VisitType} from './visit';
import {Patient} from './patient';
import {PhysicianTeam} from './physician-team';
import {Human} from './human';
import {SimpleDatePipe} from '../@theme/pipes/simple-date.pipe';
import {Facility} from './facility';
import {PatientDetailInvalidateKey} from '../@core/patient.service';

export interface TaskProposalDetails {
    requested_by?: string;
    primary_concern?: string;
    symptoms?: string;
    history?: string;
    interventions?: string;
}

export type TaskProposalQueryKey = 'physician_team' | 'visit' | 'patient' | 'organization' | 'facility';

export type TaskProposalQuery = {[key in TaskProposalQueryKey]?: any};

export class TaskProposal extends ModelBase implements Modify<TaskProposalSerializer, {visit: Visit; patient: Patient}>, TaskProposalForPatientsSerializer, TaskProposalQuery {
    id: number;
    patient: Patient;
    type: string;
    user_facility: number;
    physician_team: number;
    physicianTeam: PhysicianTeam;
    confirmed: boolean;
    sort: number;
    visit: Visit;
    created_by: PhysicianNameSerializer;
    created_at?: Date | string;
    visit_type: VisitType;
    details: TaskProposalDetails;
    comment: string;

    static viewSet = APIService.TaskProposalViewSet;
    static patientDetailKey: PatientDetailInvalidateKey = 'taskProposals';

    assign(proposal: TaskProposalSerializer) {
        super.assign(proposal);

        this.physician_team = getId(this.physician_team); // temporary fix because of cached serializers
        if (this.patient && !(this.patient instanceof Patient)) this.patient = new Patient(this.patient);
        if (Array.isArray(this.details)) this.details = {}; // fix empty array saved for empty details value on backend

        this.physicianTeam = this.physician_team as any;
        this.setAccessor('physicianTeam', PhysicianTeam);
        this.setAccessor('visit', Visit);
    }

    isConfirmedForVisit(visit: Visit) {
        return this.confirmed && this.visit == visit;
    }

    get tooltip() {
        return TaskProposal.getTooltip(this);
    }

    get organization() {
        return getId(this.patient?.organization || Facility.get(this.facility)?.organization);
    }

    get facility() {
        return getId(this.patient?.facility);
    }

    static getTooltip(proposal: TaskProposal): string {
        if (!proposal.created_by) return null;

        const segments = [`Proposed by ${Human.getName(proposal.created_by)} @ ${SimpleDatePipe.transform(proposal.created_at)}`];
        if (VISIT_TYPES[proposal.visit_type]) segments.push(VISIT_TYPES[proposal.visit_type]);

        return segments.join(' - ');
    }
}
