import {Patient} from './patient';

type LocationInfo = {id: number; name: string};

export class CriticalVitalEntry {
    patient?: Patient;
    risks: any[];
    types?: any[];
    facility?: LocationInfo;
    census_location: LocationInfo;
    census_bed: LocationInfo;
    id: number;

    constructor(x, needsPatient = true) {
        if (needsPatient) {
            this.patient = x.patient && typeof x.patient !== 'number' ?
                x.patient instanceof Patient ? x.patient : new Patient(x.patient) :
                new Patient({id: x.id, first_name: x.first_name, last_name: x.last_name, facility: x.facility, physician: x.physician, date_ob: x.date_ob});
        }
        this.risks = x.risks.sort((a, b) => (new Date(b.recorded || b.to).getTime() - new Date(a.recorded || a.to).getTime()));
        this.types = this.risks.reduce((acc, curr) => {
            let t = acc.find(x => x.name == curr.type.name);
            if (!t) {
                t = {name: curr.type.name, risks: []};
                acc.push(t);
            }
            t.risks.push(curr);
            return acc;
        }, []);
        if (x.facility) this.facility = x.facility;
        if (x.census_location) this.census_location = x.census_location;
        if (x.census_bed) this.census_bed = x.census_bed;
    }
}
