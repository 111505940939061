import {Paginated} from '../api.service';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../models/user';
import {assignOrCreate} from '../../models/model-base';

export type RepoAction = 'add' | 'update' | 'remove';

const TTL_SECS = 60;
const RETRY_SECS = 10;

export interface BaseCacheArgs<T> {
    constructorClass?: any;
    sortingFn?: SortingFn;
    compareProp?: string;
    ttlSecs?: number;
    permissions?: string[];
    user?: Observable<User>;
}

export interface CacheArgs<T, C> extends BaseCacheArgs<T> {
    apiEndpoint?: Observable<T | Paginated<T>>;
    processItem?: (x: T) => C;
}

export type SortingFn = (a, b) => number;

export abstract class CacheBase<T, C = T> {
    ttl: number;
    data: C;
    obs$ = new Subject<C>(); // should be private
    private _apiEndpoint$: Observable<T | Paginated<T>>;
    private readonly _ttlSecs: number;
    constructorClass;
    private _permissions: string[];
    private _user: User;
    private _error;

    private _cancelRequest$ = new Subject<void>();

    constructor(args?: CacheArgs<T, C>) {
        if (args) {
            this._apiEndpoint$ = args.apiEndpoint || null;
            this._ttlSecs = typeof args.ttlSecs === 'number' ? args.ttlSecs : TTL_SECS;
            this._permissions = args.permissions;
            args.user?.subscribe(u => this._user = u);
            if (args.constructorClass) this.constructorClass = args.constructorClass;
            if (args.processItem) this.processItem = args.processItem;
        }
    }

    get isObserved(): boolean {
        return subjectIsObserved(this.obs$);
    }

    set apiEndpoint$(endpoint: Observable<T | Paginated<T>>) {
        this._apiEndpoint$ = endpoint;
        this.reset();
        if (this.isObserved) this.getData().subscribe().unsubscribe();
    }

    getData(forceFetch = false): Observable<C> {
        return new Observable(observer => {
            const sub = this.obs$.subscribe(observer);
            const hasPermission = this._permissions?.length ? this._permissions.some(x => this._user.permissions.includes(x)) : true;

            if (this._apiEndpoint$) {
                if (hasPermission) {
                    if (forceFetch || !this.ttl || this.ttl < new Date().getTime()) {
                        this.ttl = new Date().getTime() + this._ttlSecs * 1000;
                        this.data = undefined;
                        this._error = null;
                        this._cancelRequest$.next();
                        this._apiEndpoint$.pipe(takeUntil(this._cancelRequest$)).subscribe({
                            next: res => {
                                this.data = this.processResponse(res);
                                this.obs$.next(this.data);
                            },
                            error: err => {
                                this.ttl = new Date().getTime() + RETRY_SECS * 1000;
                                this._error = err;
                                observer.error(err);
                            },
                        });
                    } else if (this._error) {
                        observer.error(this._error);
                    } else if (this.data !== undefined) {
                        observer.next(this.data);
                    }
                } else {
                    this.data = null;
                    observer.next(null);
                }
            }

            return () => sub.unsubscribe();
        });
    }

    static processItem(repo: {constructorClass}, item: any): any {
        if (!repo.constructorClass) return item;

        if (repo.constructorClass.isModelBase) return assignOrCreate(repo.constructorClass, item);

        return !(item instanceof repo.constructorClass) ? new repo.constructorClass(item) : item;
    }

    processItem(x) {
        return CacheBase.processItem(this, x);
    }

    processResponse(res: T | Paginated<T>): C {
        return this.processItem(res);
    }

    reset() {
        this.ttl = null;
        this._cancelRequest$.next();
        if (this.data) {
            this.data = undefined;
            this.obs$.next(undefined);
        }
    }

    invalidate(fetchIfObserved = true) {
        this.ttl = null;
        this._cancelRequest$.next();
        if (this.isObserved && fetchIfObserved) this.getData().subscribe().unsubscribe();
    }
}

export class ItemCache<T, C = T> extends CacheBase<T, C> {
    processItem(x: T): C {
        return CacheBase.processItem(this, x);
    }
}

export function subjectIsObserved(subj: Subject<any>) {
    return !!subj?.observers?.length;
}

export function syncArray<T>(target: T[], source: any[], processItemFn: (x) => T = x => x, sortingFn?: (a, b) => number, compareProp = 'id'): T[] {
    if (sortingFn) source.sort(sortingFn);
    if (target?.length) {
        source.forEach((x, i) => {
            const o = processItemFn(x);
            const si = target.slice(i).findIndex(y => y[compareProp] === x[compareProp]);
            if (si > -1) {
                if (si > 0) target.splice(i, si);
                Object.assign(target[i], o);
            } else {
                target.splice(i, 0, o);
            }
        });
        if (source.length < target.length) target.splice(source.length, target.length - source.length);
    } else {
        target = source.map(processItemFn);
    }
    return target;
}
