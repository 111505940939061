import {Injectable} from '@angular/core';
import {APIService, PatientWithFacilitySerializer, TaskProposalCreateSerializer, TaskProposalSerializer} from './api.service';
import {TaskProposal, TaskProposalDetails, TaskProposalQuery} from '../models/task-proposal';
import {map, Observable, of, share, Subject, switchMap, tap, throwError} from 'rxjs';
import {Patient} from '../models/patient';
import {getId} from '../utils/type.utils';
import {SlidePanelService} from '../portal/slide-panel/slide-panel.service';
import {Visit} from '../models/visit';
import {EntryUpdate} from '../models/entry-update';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastService} from './toast.service';
import {assignOrCreate} from '../models/model-base';
import {compareDates} from '../@theme/helpers';
import {Task} from '../models/models';
import {extractErrorMessage} from '../utils/error.utils';
import {PatientDetailInvalidateKey} from './patient.service';
import {CacheRepo} from './utils/cache-repo';
import {cacheIdSegmentDelimiter, MethodCache, MethodUtils} from '../utils/accessor.utils';

export interface TaskProposalEditInterface {
    id: number | 'new';
    patient: Patient;
    taskType: string;
    visit: Visit;
    comment: string;
    confirmed: boolean;
    details: TaskProposalDetails;
    physician_team: number;
}

const taskProposalCacheIdSegmentsFn: MethodUtils.CacheIdSegmentsFn = (args: TaskProposalQuery) => [
    args.patient,
    args.facility,
    args.organization,
    args.visit,
    args.physician_team,
];

@Injectable({
    providedIn: 'root',
})
export class TaskProposalService {
    invalidatePatient$ = new Subject<{id: number; patientDetailKey: PatientDetailInvalidateKey}>();

    private _updates$ = new Subject<EntryUpdate<TaskProposal>>();

    constructor(private slidePanelService: SlidePanelService,
                private toastService: ToastService) {
    }

    get updates$() {
        return this._updates$.asObservable();
    }

    getProposals$(query: TaskProposalQuery, nonCacheFilters?: {patient_ids: number[]}, forceFetch?: boolean): Observable<TaskProposal[]> {
        if (!query) {
            const err = 'A UserFacility, a Visit, an Organization, a Facility or a Patient needs to be provided';
            console.error(err);
            return throwError(err);
        }
        const repo = this._getProposal(query, nonCacheFilters);
        return repo.getData(forceFetch);
    }

    @MethodCache(taskProposalCacheIdSegmentsFn)
    private _getProposal(taskProposalCacheFilters: TaskProposalQuery, nonCacheFilters?: {patient_ids: number[]}) {
        return new CacheRepo<TaskProposalSerializer, TaskProposal>({
            apiEndpoint: APIService.TaskProposalViewSet.list({...taskProposalCacheFilters, ...nonCacheFilters}),
            processItem: x => assignOrCreate(TaskProposal, x),
            sortingFn: (a, b) => compareDates(a.visit?.date, b.visit?.date, false),
        });
    }

    retrieveProposal$(id: number): Observable<TaskProposal> {
        return (TaskProposal.retrieve(id) as Observable<TaskProposal>).pipe(tap(proposal => {
            this._updateRepos(proposal);
        }));
    }

    openEditProposal(patient: Patient | PatientWithFacilitySerializer, taskId: number | 'new' = 'new', taskProposalData: Partial<TaskProposalEditInterface> = {}, intent?: Subject<TaskProposal>, visitUpdates$?: Subject<Visit | any>) {
        this.slidePanelService.addComponent(taskId !== 'new' ? 'TASK_PROPOSAL_EDIT' : 'TASK_PROPOSAL_NEW', taskId === 'new' ? patient.id : taskId, null, true, {
            componentInputs: {patient, ...taskProposalData, intent, visitUpdates$},
        });
    }

    removeProposal(proposal: TaskProposal | number): Observable<boolean> {
        const id = getId(proposal);
        const p: TaskProposal = TaskProposal.get(id);

        const obs$ = APIService.TaskProposalViewSet.destroy(id).pipe(
            map(() => {
                this._getRelatedRepos(p).forEach(repo => repo.remove({id}));
                return true;
            }),
            tap(() => {
                if (p) this.invalidatePatient$.next({id: getId(p.patient), patientDetailKey: TaskProposal.patientDetailKey});
            }),
            share());
        obs$.subscribe(() => this._updates$.next({type: 'delete', id: getId(proposal)}));

        return obs$;
    }

    confirmProposal(proposal: TaskProposal | number, confirm: boolean, visit?: Visit | number): Observable<TaskProposal> {
        const id = getId(proposal);
        if (!id) return;
        const data: TaskProposalCreateSerializer = {confirmed: confirm};
        if (visit) data.visit = getId(visit);
        const obs$ = this.saveProposal(data, id).pipe(share());
        obs$.subscribe();
        return obs$;
    }

    unConfirmProposal(proposal: TaskProposal | number): Observable<TaskProposal> {
        return this.confirmProposal(proposal, false);
    }

    discardProposal(proposal: TaskProposal | number) {
        return this.removeProposal(proposal);
    }

    saveProposal(proposal: TaskProposalSerializer | TaskProposalCreateSerializer | TaskProposal, id?: number, intent?: Subject<TaskProposal>, showToast = true): Observable<TaskProposal> {
        const data: TaskProposalCreateSerializer = {
            type: proposal.type,
            confirmed: proposal.confirmed,
            visit: getId(proposal.visit),
            visit_type: proposal.visit_type,
            comment: proposal.comment,
            physician_team: proposal.physician_team,
        };
        if (!id && proposal.patient) data.patient = getId(proposal.patient);
        if (proposal.details) data.details = proposal.details;
        if (!proposal.details || Array.isArray(proposal.details)) data.details = {}; // fix empty array saved for empty details value on backend

        return this._updateOrCreateProposal(id, data, intent, showToast);
    }

    private _updateOrCreateProposal(id, data: TaskProposalCreateSerializer, intent?: Subject<TaskProposal>, showToast = true): Observable<TaskProposal> {
        const obs$ = (id ? APIService.TaskProposalViewSet.partial_update(id, data) : APIService.TaskProposalViewSet.create(data)).pipe(
            tap({
                next: () => {
                    if (showToast) this.toastService.success('Visit Proposal was saved successfully'); // .onTap.subscribe(() => this.openPlanner());
                },
                error: err => this._throwError(err, showToast, 'An error occurred while trying to save Visit Proposal'),
            }),
            map(res => assignOrCreate(TaskProposal, res)),
            tap(proposal => {
                this._updateRepos(proposal, !!id);
                this._updates$.next({type: id ? 'update' : 'add', id: proposal.id, entry: proposal});
                if (intent && !intent.closed) {
                    intent.next(proposal);
                    intent.complete();
                }
            }),
            share()
        );
        obs$.subscribe();
        return obs$;
    }

    invalidate() {
        this._geTaskProposalRepos().forEach(repo => repo.repoValue.invalidate());
    }

    reset() {
        this._geTaskProposalRepos().forEach(repo => {
            repo.repoValue.reset();
            MethodUtils.clearCache(this);
        });
    }

    markTaskReviewedForScheduling(task: Task, taskProposal?: TaskProposal) {
        const obs$ = APIService.TaskViewSet.review_for_scheduling(getId(task), {task_proposal: getId(taskProposal)}).pipe(share());
        obs$.subscribe(res => {
            this.toastService.success('Marked Task reviewed for Scheduling');
            task.scheduling_review = res;
        }, err => {
            this.toastService.error(extractErrorMessage(err, 'mark Task reviewed for Scheduling'));
        });
        return obs$;
    }

    openAddProposalFromTaskVisitDetails(task?: Task, proposalData: Partial<TaskProposalEditInterface> = {}, markTaskReviewedForScheduling = true) {
        const intent$ = new Subject<TaskProposal>();
        this.openEditProposal(
            proposalData.patient || task?.patient,
            'new',
            {
                physician_team: task?.physician_team,
                taskType: task?.visit_details?.next_appointment_type,
                details: {primary_concern: task?.visit_details?.reason_for_next_visit?.slice(0, 72)},
                comment: task?.visit_details?.reason_for_next_visit,
                ...proposalData,
            },
            intent$,
        );
        const obs$ = intent$.pipe(
            switchMap(taskProposal => markTaskReviewedForScheduling ? this.markTaskReviewedForScheduling(task, taskProposal) : of(null)),
            share(),
        );
        obs$.subscribe();
        return obs$;
    }

    private _throwError(err: any, showToast = true, message = 'An error occurred while trying to save data') {
        if (showToast) this.toastService.error(extractErrorMessage(err, null, message));
        throw new HttpErrorResponse(err);
    }

    private _updateRepos(proposal: TaskProposal, removeCheck = true) {
        if (removeCheck) {
            this._geTaskProposalRepos().forEach(repo => {
                repo.repoValue.data?.forEach(x => {
                    if (x.id === proposal.id) {
                        if (this._regeneratedRepoKey(proposal, repo.repoKey) !== repo.repoKey) repo.repoValue.remove(x);
                    }
                });
            });
        }
        this._getRelatedRepos(proposal).forEach(repo => repo.addOrUpdate(proposal));
    }

    private _geTaskProposalRepos() {
        return Object.values(
            MethodUtils.getCacheEntry(this, 'getProposals$')
        ).reduce<{repoKey: string; repoValue: CacheRepo<TaskProposalSerializer, TaskProposal>}[]>((acc, currentRepo) => acc.concat(Array.from(currentRepo.entries()).map(([repoKey, repoValue]) => ({repoKey, repoValue}))), []);
    }

    private _getRelatedRepos(taskProposal: TaskProposal) {
        return this._geTaskProposalRepos().reduce<CacheRepo<TaskProposalSerializer, TaskProposal>[]>((acc, {repoKey, repoValue}) => {
            if (this._regeneratedRepoKey(taskProposal, repoKey) === repoKey) acc.push(repoValue);
            return acc;
        }, []);
    }

    private _regeneratedRepoKey(proposal: TaskProposal, repoKey: string) {
        const newlyGeneratedCacheKey = taskProposalCacheIdSegmentsFn(proposal).map(x => getId(x)?.toString());
        repoKey.split(cacheIdSegmentDelimiter).forEach((originalRepoKeySegment, i) => {
            newlyGeneratedCacheKey[i] = originalRepoKeySegment ? newlyGeneratedCacheKey[i] : '';
        });
        return newlyGeneratedCacheKey.join(cacheIdSegmentDelimiter);
    }
}
