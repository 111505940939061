import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {InfectionEntry} from '../models/models';

@Injectable({
    providedIn: 'root',
})
export class InfectionEntryService {
    private _updateEntries$ = new Subject<InfectionEntry[]>();

    get updateEntries$() {
        return this._updateEntries$.asObservable();
    }

    updateEntries(newEntries: InfectionEntry[]) {
        this._updateEntries$.next(newEntries);
    }
}
