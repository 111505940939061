import {Observable, Subject} from 'rxjs';
import {Paginated} from '../api.service';
import {CacheArgs, CacheBase, SortingFn, syncArray} from './cache-base';
import {Modify} from '../../utils/type.utils';

interface CacheItems<T, C> {
    ttl: number;
    data: C[];
    obs$: Subject<C[]>;
    constructorClass;
    processItem?: (x: T) => C;
}

export type CacheRepoArgs<T, C> = Modify<CacheArgs<T, C>, {
    apiEndpoint?: Observable<T[] | Paginated<T[]>>;
}>;

export class CacheRepo<T, C = T> extends CacheBase<T[], C[]> implements CacheItems<T, C> {
    compareProp = 'id';

    private readonly _sortingFn: SortingFn;

    constructor({processItem, ...args}: CacheRepoArgs<T, C>) {
        super(args);

        if (args) {
            if (args.sortingFn) this._sortingFn = args.sortingFn;
            if (args.compareProp) this.compareProp = args.compareProp;
            if (processItem) this.processItem = processItem;
        }
    }

    processResponse(res: T[] | Paginated<T[]>) {
        if (!res) return undefined;
        const arr = Array.isArray(res) ? res : res.results;
        return syncArray<C>(this.data, arr, x => this.processItem(x), this._sortingFn);
    }

    processItem(x: T): C {
        return CacheBase.processItem(this, x);
    }

    static addTo(repo: CacheItems<any, any>, item: any, sortingFn?: SortingFn, compareProp = 'id'): any {
        const x = repo.processItem ? repo.processItem(item) : CacheRepo.processItem(repo, item);
        if (repo?.data?.every(x => x[compareProp] !== item[compareProp])) {
            let i = sortingFn ? repo.data.findIndex(b => sortingFn(x, b) < 0) : repo.data.length;
            if (i < 0) i = repo.data.length;
            repo.data.splice(i, 0, x);
            repo.obs$.next(repo.data);
        }
        return x;
    }

    static removeFrom(repo: CacheItems<any, any>, item: any, compareProp = 'id'): boolean {
        if (repo?.data) {
            const i = repo.data.findIndex(x => x[compareProp] === item[compareProp]);
            if (i > -1) {
                repo.data.splice(i, 1);
                repo.obs$.next(repo.data);
                return true;
            }
        }
        return false;
    }

    static update(repo: CacheItems<any, any>, item: any, compareProp = 'id'): any {
        const x = repo.processItem ? repo.processItem(item) : CacheRepo.processItem(repo, item);
        if (repo?.data) {
            const o = repo.data.find(x => x[compareProp] === item[compareProp]);
            if (o) {
                // TODO: implement sortingFn if needed
                Object.assign(o, x);
                repo.obs$.next(repo.data);
            }
        }
        return x;
    }

    static addToOrUpdate(repo: CacheItems<any, any>, item: any, sortingFn?: SortingFn, compareProp = 'id'): any {
        if (repo?.data?.some(x => x[compareProp] === item[compareProp])) {
            return CacheRepo.update(repo, item, compareProp);
        }
        return CacheRepo.addTo(repo, item, sortingFn, compareProp);
    }

    add(item: T | C, compareProp = this.compareProp): C {
        return CacheRepo.addTo(this, item, this._sortingFn, compareProp);
    }

    remove(item: T | C, compareProp = this.compareProp): boolean {
        return CacheRepo.removeFrom(this, item, compareProp);
    }

    update(item: T | C, compareProp = this.compareProp): C {
        return CacheRepo.update(this, item, compareProp);
    }

    addOrUpdate(item: T | C, compareProp = this.compareProp): C {
        return CacheRepo.addToOrUpdate(this, item, this._sortingFn, compareProp);
    }
}
